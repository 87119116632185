import "quasar/dist/quasar.css";
import lang from "quasar/lang/zh-TW.js";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {},
  lang: lang
};
