<template>
  <q-page>
    <div class="home">
      <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
      <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
      <CaptionCarousel />
      <div class="row" style="margin-top:-5%">
        <template v-for="(data, idx) in mediaCardList" :key="idx">
          <div class="col-12 col-md-4">
            <MediaCard :title="data.title" :src="data.src" />
          </div>
        </template>
      </div>
    </div>
  </q-page>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import CaptionCarousel from "@/components/CaptionCarousel.vue";
import MediaCard from "@/components/MediaCard.vue";

export default {
  setup() {
    return {
      mediaCardList: [
        {
          title: "服飾設計與經營學系學系簡介Brief Introduction",
          src: "https://www.youtube.com/embed/zZ-gnMWI3JA"
        },
        {
          title: "服飾設計與經營學系 2022AW台北時裝週展演",
          src: "https://www.youtube.com/embed/C3bJH2SDkMs"
        },
        {
          title: "畢業設計展",
          src: "https://www.youtube.com/embed/GztYC63v7sk"
        }
      ]
    };
  },
  name: "Home",
  components: {
    // HelloWorld,
    CaptionCarousel,
    MediaCard
  }
};
</script>
