<template>
  <div class="q-pa-md">
    <q-card class="full-width">
      <q-video :ratio="16 / 9" :src="src" />
      <q-card-section>
        <div class="text-h6">{{ title }}</div>
        <!-- <div class="text-subtitle2">by John Doe</div> -->
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    src: String
  },
  setup() {
    return {};
  }
};
</script>
