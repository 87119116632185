<template>
  <q-layout view="hHh lpr fff">
    <q-header
      reveal
      elevated
      class="bg-deep-orange-5 text-white"
      height-hint="98"
    >
      <span class="lt-md inline">
        <q-toolbar>
          <q-toolbar-title>
            <q-item to="/" exact>
              <q-item-section avatar>
                <q-avatar>
                  <img
                    style="background-color: white"
                    src="./assets/toolbar_logo.png"
                  />
                </q-avatar>
              </q-item-section>
            </q-item>
          </q-toolbar-title>
          <span class="lt-md inline"
            ><q-btn dense flat round icon="menu" @click="toggleRightDrawer"
          /></span>
        </q-toolbar>
      </span>
      <span class="gt-sm inline">
        <q-item to="/" clickable style="padding:0">
          <q-item-section>
            <!-- <img src="https://cdn.quasar.dev/img/avatar.png" /> -->
            <img
              style="background-color: white"
              src="./assets/homepage_logo.png"
            />
          </q-item-section>
        </q-item>
        <q-tabs align="right">
          <q-route-tab to="/news" label="最新消息" />
          <q-route-tab to="/about" label="學系簡介" />
          <q-route-tab to="/course" label="專業課程" />
          <q-route-tab to="/award" label="獲獎榮譽" />
          <q-route-tab to="/activity" label="學生活動" />
          <q-route-tab to="/media" label="影片專區" />
        </q-tabs>
      </span>
    </q-header>

    <q-drawer
      class="bg-grey-10"
      v-model="rightDrawerOpen"
      side="right"
      overlay
      bordered
    >
      <!-- drawer content -->
      <div class="q-pa-md bg-grey-10 text-white">
        <q-list dark no-border separator style="max-width: 318px">
          <q-item to="/news" clickable v-ripple>
            <q-item-section>最新消息</q-item-section>
          </q-item>
          <q-item to="/about" clickable v-ripple>
            <q-item-section>學系簡介</q-item-section>
          </q-item>
          <q-item to="/course" clickable v-ripple>
            <q-item-section>專業課程</q-item-section>
          </q-item>
          <q-item to="/award" clickable v-ripple>
            <q-item-section>獲獎榮譽</q-item-section>
          </q-item>
          <q-item to="/activity" clickable v-ripple>
            <q-item-section>學生活動</q-item-section>
          </q-item>
          <q-item to="/media" clickable v-ripple>
            <q-item-section>影片專區</q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-drawer>

    <q-page-container>
      <router-view />
      <q-page-scroller position="right" :scroll-offset="150" :offset="[18, 18]">
        <q-btn fab icon="keyboard_arrow_up" color="deep-orange-5" />
      </q-page-scroller>
    </q-page-container>

    <q-footer elevated class="bg-grey-8 text-white">
      <q-toolbar>
        <q-toolbar-title>
          <div class="row">
            <div class="col-12 col-md-4">
              <img
                style="display: block; margin: 10% auto; width: 45%;"
                src="./assets/fdm_logo.png"
              />
            </div>
            <div class="col-12 col-md-4">
              <div style="text-align: center; margin: 5% auto;">
                <p>DEPARTMENT OF<br />FASHION DESIGN & MERCHANDISING</p>
                <p>
                  服飾設計與經營學系
                </p>
                <p>
                  地址:高雄市內門區大學路200號<br />
                  電話:(07)667-8888#4321
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div style="text-align: center; margin: 5% auto;" class="q-pa-md">
                <q-btn
                  size="22px"
                  flat
                  rounded
                  label="入學管道"
                  type="a"
                  href="https://recruit.kh.usc.edu.tw/"
                  target="_blank"
                />
              </div>
              <div style="text-align: center; margin: 5% auto;">
                <q-btn
                  flat
                  rounded
                  type="a"
                  href="https://zh-tw.facebook.com/fdmshihchien/"
                  target="_blank"
                  ><q-tooltip>
                    facebook
                  </q-tooltip>
                  <i class="fab fa-facebook fa-5x"></i
                ></q-btn>
                <q-btn
                  flat
                  rounded
                  type="a"
                  href="https://www.youtube.com/channel/UCMzrWgRAkMY3zBE-_UXwSbA"
                  target="_blank"
                  ><q-tooltip>
                    youtube
                  </q-tooltip>
                  <i class="fab fa-youtube fa-5x"></i
                ></q-btn>
                <q-btn
                  flat
                  rounded
                  type="a"
                  href="https://www.instagram.com/f_d_m_official/"
                  target="_blank"
                  ><q-tooltip>
                    instagram
                  </q-tooltip>
                  <i class="fab fa-instagram fa-5x"></i
                ></q-btn>
              </div>
            </div>
          </div>
        </q-toolbar-title>
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const rightDrawerOpen = ref(false);

    return {
      rightDrawerOpen,
      toggleRightDrawer() {
        rightDrawerOpen.value = !rightDrawerOpen.value;
      }
    };
  }
};
</script>
