<template>
  <div class="q-pa-md">
    <q-responsive :ratio="16 / 9">
      <q-carousel
        :autoplay="autoplay"
        v-model="slide"
        swipeable
        animated
        :arrows="arrows"
        control-color="orange"
        infinite
      >
        <q-carousel-slide :name="1" class="column no-wrap">
          <q-scroll-area class="fit">
            <div
              class="row justify-start items-center q-gutter-xs q-col-gutter no-wrap"
            >
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3326_7946783_81450.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3327_896022_81442.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3328_1700365_81434.jpg"
              />
            </div>
          </q-scroll-area>
        </q-carousel-slide>
        <q-carousel-slide :name="2" class="column no-wrap">
          <q-scroll-area class="fit">
            <div
              class="row justify-start items-center q-gutter-xs q-col-gutter no-wrap"
            >
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3329_4992523_81426.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3330_7509422_81418.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3331_2327412_81411.jpg"
              />
            </div>
          </q-scroll-area>
        </q-carousel-slide>
        <q-carousel-slide :name="3" class="column no-wrap">
          <q-scroll-area class="fit">
            <div
              class="row justify-start items-center q-gutter-xs q-col-gutter no-wrap"
            >
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3332_7817213_81401.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3333_1735528_81393.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3334_3551327_81383.jpg"
              />
            </div>
          </q-scroll-area>
        </q-carousel-slide>
        <q-carousel-slide :name="4" class="column no-wrap">
          <q-scroll-area class="fit">
            <div
              class="row justify-start items-center q-gutter-xs q-col-gutter no-wrap"
            >
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3335_3985257_81375.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3336_4873033_81368.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3337_8035881_81359.jpg"
              />
            </div>
          </q-scroll-area>
        </q-carousel-slide>
        <q-carousel-slide :name="5" class="column no-wrap">
          <q-scroll-area class="fit">
            <div
              class="row justify-start items-center q-gutter-xs q-col-gutter no-wrap"
            >
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3338_5496895_81349.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3339_3740029_81339.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3340_5872032_81329.jpg"
              />
            </div>
          </q-scroll-area>
        </q-carousel-slide>
        <q-carousel-slide :name="6" class="column no-wrap">
          <q-scroll-area class="fit">
            <div
              class="row justify-start items-center q-gutter-xs q-col-gutter no-wrap"
            >
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3341_1849026_81320.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3342_1233086_81310.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3343_7846709_81302.jpg"
              />
            </div>
          </q-scroll-area>
        </q-carousel-slide>
        <q-carousel-slide :name="7" class="column no-wrap">
          <q-scroll-area class="fit">
            <div
              class="row justify-start items-center q-gutter-xs q-col-gutter no-wrap"
            >
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3344_8555122_02090.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3345_2633137_02120.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3346_2158845_02139.jpg"
              />
            </div>
          </q-scroll-area>
        </q-carousel-slide>
        <q-carousel-slide :name="8" class="column no-wrap">
          <q-scroll-area class="fit">
            <div
              class="row justify-start items-center q-gutter-xs q-col-gutter no-wrap"
            >
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3347_985897_81262.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3348_8042891_81250.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3349_8692753_81240.jpg"
              />
            </div>
          </q-scroll-area>
        </q-carousel-slide>
        <q-carousel-slide :name="9" class="column no-wrap">
          <q-scroll-area class="fit">
            <div
              class="row justify-start items-center q-gutter-xs q-col-gutter no-wrap"
            >
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3351_6473397_81220.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3350_4193883_81230.jpg"
              />
              <q-img
                class="rounded-borders col-4"
                src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3352_4697039_81206.jpg"
              />
            </div>
          </q-scroll-area>
        </q-carousel-slide>
        <!-- <template v-slot:control>
          <q-carousel-control
            position="bottom"
            :offset="[16, -6]"
            class="my-carousel text-deep-orange-5 text-weight-bold text-center rounded-borders"
          >
            2022AW台北時裝週發表，主題「智造無界未來」，<br />將數位科技與服裝設計結合，呈現多元的創意風格。
          </q-carousel-control>
        </template> -->
      </q-carousel>
    </q-responsive>
  </div>
  <!-- <div>
    <p class="my-font text-deep-orange-5 text-weight-bold text-center ">
      2022AW台北時裝週發表，主題「智造無界未來」，<br />將數位科技與服裝設計結合，呈現多元的創意風格。
    </p>
  </div> -->
</template>
<!-- 
<style>
@media screen and (min-width: 1024px) {
  .my-carousel {
    font-size: 1.6em;
  }
  .my-font {
    display: none;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .my-carousel {
    font-size: 1.4em;
  }
  .my-font {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .my-carousel {
    display: none;
  }
  .my-font {
    font-size: 1em;
  }
}
</style> -->

<script>
import { ref } from "vue";

export default {
  setup() {
    return {
      slide: ref(1),
      arrows: ref(true),
      autoplay: ref(true)
    };
  }
};
</script>
